.split{
    display: flex;
    gap: 20px;
}
.baslik-triangle{
  display: flex;
  align-items: center;
}
.social-bslk{
  font-size: clamp(1.8rem, 1.4316rem + 1.4737vw, 3rem);
}
.split .triangle{
    display: flex;
   
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 6%, #6effaf 59%);
    -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    opacity: .15;

    height: 100px;
    width: 75px;
    animation: opac alternate;
  
}
.social-banner{
    color: #fff;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: var(--greenColor);
    align-items: center;
    padding: 20px 80px 20px 80px;
    border-radius: 12px;
    scale: .9;
}
.social-icons{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}
.social-icons .social-icon{
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    
   
}
.social-icons a:not(:last-child) {
  }
.social-icons .social-icon i{
    position: relative;
    background-color: #fff;
    color:var(--greenColor);
    padding: 15px;
    border-radius: 50%;
    font-size: 23px;
    align-items: center;
    transition: all 0.3s ease;
    margin-bottom: 15px ;
}

.social-icons .social-icon h3{
    color: hsla(0, 0%, 100%, .5);
    transition: all 0.3s ease;
}

/* hover */
.social-icons .social-icon:hover h3{
   color: #fff;
}
.social-icons .social-icon:hover i{
    scale: .8;
    background-color: var(--greenColor);
    color: #fff;
}




@keyframes fadeInOut {
    0% {
      opacity: 0.15;
    }
    20% {
      opacity: .4;
    }
    50% {
      opacity: 0.15;
    }
    100% {
      opacity: 0.15;
    }
  }
  .split .triangle:nth-child(1) {
    animation: fadeInOut 2.5s ease infinite 0.2s;
  }
  
  .split .triangle:nth-child(2) {
    animation: fadeInOut 2.5s ease infinite 0.4s;
  }
  
  .split .triangle:nth-child(3) {
    animation: fadeInOut 2.5s ease infinite .6s;
  }
  @media screen and (max-width:1280px) {
    .social-banner{
      
      padding: 20px 20px 20px 20px;
      border-radius: 12px;
      scale: .9;
  }
  .social-icons .social-icon{
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 14px 8px 14px 8px;
    
   
}
  }
  @media screen and (max-width:1024px) {
   
    .social-banner{
      
      flex-direction: column;
      padding: 20px 20px 20px 20px;
      border-radius: 12px;
      scale: .9;
  }

  }
  @media (max-width: 614px) {
    .social-icons .social-icon {
        flex: 0 0 45%; /* İki sütun halinde göster */
        margin: 10px; /* Elemanlar arasına boşluk ekle */
    }

    .social-icons a:not(:last-child) {
        margin-right: 0; /* Ekran genişliği 614px üzerindeyken sağ margin'i kaldır */
    }
    .social-icons a:last-child {
      
      margin-right: 15px; /* İstenilen padding değeri */
  }
}
  @media (max-width: 320px) {
   
    .social-icons a:last-child {
      
      margin-right: 0px; /* İstenilen padding değeri */
  }
}