/* .hero-field{
    display: flex;
    height: calc(100svh - 125px);
    height: 100vh;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    pointer-events: none;
}
.background-wrapper{
    position: relative;
    margin-top: 120px;
    overflow: hidden;
    height: calc(100svh - 140px);
    width: 100%;
    background: linear-gradient(rgb(15, 117, 188,.40),rgb(15, 117, 188,.30)),url('../Assets/2n-header.png') ;
    --tw-bg-opacity: 1;
    background-color: rgb(7 37 74 / var(--tw-bg-opacity));
    mask-size: cover;
    mask-repeat: no-repeat;
    border-radius: 3.4rem;
    scale: .8;
    transition: all 0.9 ease-in;

   
    
    
}
.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(3px); 
}

.inner{
    height: 100%;
    position: relative;
    outline: 2px solid transparent !important;
    outline-offset: 2px !important;
    text-align: center;
    align-items: center;
}
.background-wrapper .icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -40px; 
    left: 50%; 
    transform: translateX(-50%); 
    font-size: 30px;
    background-color: #fff;
    
    border-radius: 0%;
    border: 15px solid white;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;
   
}
.background-wrapper .icon i{
    transform: translateY(-35%);
    animation: scrolldown 1.2s ease-in-out infinite 0.15s alternate-reverse;
    opacity: 1;

}
@keyframes scrolldown {
    0%{
        transform: translateY(-35%) ;
        opacity: 0.2;
    }
    50%{
        transform: translateY(-10%);
        opacity: 0.7;
    }
    100%{
        transform: translateY(-1%);
        opacity: 0.7;
    }
    
}
.background-wrapper .icon i{
    color: #707070;
} */

/* radiuslar */
/* .hero-field .background-wrapper:before{
    content: "";
    z-index: 9999;
    position: absolute;
    bottom: 0;
    left: 51.6%;
    width: 28px;
    height:28px;
    background-color: transparent;
    border-radius: 50%;

    box-shadow: -11px 15px 0 #ffffff;
}
.hero-field .background-wrapper:after{
    content: "";
    z-index: 9999;
    position: absolute;
    bottom: 0;
    right: 51.6%;
    width: 28px;
    height:28px;
    background-color: transparent;
    border-radius: 50%;

    box-shadow: 11px 15px 0 #ffff;
}

.icerik{
    z-index: 9999;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;  
    
  
    
}
.hero-field .inner h1{
    color: #ffff;
    opacity: 0;
      
}
.hero-field .inner p{
    width: 65ch;
    color: #fff;
    font-weight: 800;
    opacity: 0;
      
} */

/* YENİ HEADER  */

.home__swiper {
  position: relative;
  z-index: 10;
}
.home__article {
  margin-top: 120px;
  position: relative;
  padding-top: 3rem;
  width: 100%;
  height: calc(100svh - 350px);
}
.home__img,
.home__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    309deg,
    rgba(255, 255, 255, 0) 60.7%,
    rgba(255, 255, 255, 1) 61%
  );
  z-index: -1;
}
.home__img {
  object-fit: cover;
  object-position: center;
  z-index: -1;
}
/* .home__shadow{
    background-color: hsla(0, 0%, 0%, 0.14);
} */
.home__data {
  text-align: center;
}
.home_subtitle {
  color: #000000;
  font-size: clamp(3rem, 3.1rem + 2vw, 5.5rem);
  margin-bottom: -30px;
  font-family: "ageo-bold", sans-serif;
}
.home_title {
  color: var(--greenColor);
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.home_content {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.home_text {
  width: 22ch;
  font-size: 22px;
  color: var(--blueColor);
  /* mix-blend-mode: difference; */
}
.home_text li {
  padding-bottom: 5px;
}

/* .swiper-button-prev::after,
.swiper-button-next::after{
    content: ' ' !important ; 
}
.swiper-button-prev,
.swiper-button-next{
    font-size: 2rem !important;
    color: #bd1010 !important;
    width: initial !important;
    height: initial !important;
    position: relative !important;
    outline: none !important;
   

}
.swiper-navigation{
    position: absolute !important;
    z-index: 999 !important;
    height: max-content !important;
    bottom: 4rem !important;
    right: 1.5rem !important;
    display: flex !important;
    column-gap: 2.5rem !important;
    color: #bd1010;
} */

@media screen and (max-width: 1280px) {
  .home__article {
    position: relative;
    padding-top: 0rem;
  }
  .home_content {
    margin-top: -4rem;
  }
}
@media screen and (min-width: 1366px) {
  .home__article {
    height: calc(100svh - 260px);
  }
}
@media screen and (max-width: 1024px) {
  .home__article {
    margin-top: 120px;
    position: relative;
    padding-top: 3rem;
    width: 100%;
    height: calc(100svh - 110px);
  }
}
@media screen and (max-width: 768px) {
  .home__img,
  .home__shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.14609593837535018) 0%,
      rgba(255, 255, 255, 0.375) 0%,
      rgba(255, 255, 255, 0.695) 100%
    );
    z-index: -1;
  }
  .home_subtitle {
    margin-bottom: 0.2rem;
  }
  .home_text {
    width: 35ch;
    font-size: clamp(1rem, 1.3697rem + 0.9211vw, 1.875rem);
    color: var(--blueColor);
    mix-blend-mode: normal;
  }
  .home_content .home_text {
    width: 20ch;
  }
}
@media screen and (max-width: 425px) {
  .home__article {
    position: relative;
    padding-top: 3rem;
  }
  .home_content {
    margin-top: 4rem;
  }
}
.home__img {
  transition-timing-function: linear;
  transition-duration: 20s;
  transform: scale(1);
}

.swiper-slide.swiper-slide-visible .home__img,
.swiper-slide.swiper-slide-visible .home__shadow {
  transform: scale(1.1);
}
