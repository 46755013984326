.filter{
    background-color: rgba(0, 0, 0, .03)
}
.filter .container{
    padding-top: 3rem;
}
.filter .container{
    overflow: visible;
}
.propertys .card{
    position: relative;
    color: #b0b0b0;
    width: 100%;
    height: 200px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 25px;
    margin: 0 10px 0 10px;
    border-radius: 7px;
    transition: all 1s ease;
    transform: skewX(-20deg);
    border-radius: 10px;
  backdrop-filter: blur(30px);
}
.card-shadow{
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid #bebebe;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.894);
  background: linear-gradient(
    to bottom, 
    hsla(0, 0%, 100%, 0.15), 
    hsla(0, 0%, 100%, 0.15)
    
  );
  transition: all 1s ease;

  mask-image: linear-gradient(90deg, #000, transparent);
  mask-image: -webkit-gradient(linear, left top, right top, from(#000), to(transparent));
}
/* hover */

.propertys .card h2,
.propertys .card p{
    transform: skewX(20deg);
    transition: all 0.4s ease;
}
.propertys .card h2{
    color: var(--blueColor);

}
.propertys .card p{
    color:#6d6c6c ;
    font-weight: 700;

}
.propertys .card::before {
    width: 100px;
    height: 100px;
    content: "";
    position: absolute;
    background-color: rgb(144, 161, 255);
    z-index: -9999;
    border-radius: 50%;
    left: 60px;
    bottom: 20px;
    transition: all 1s;
    filter: blur(60px);
   
  }
  .propertys .card:hover::before {
    transform: translate(70px, -50px);
  }

.propertys .card-shadow:hover{
    border-color: #5a5a5a;
}
.propertys .card img{
    transform: skewX(20deg);
    /* -webkit-filter: grayscale(100%);
    filter: grayscale(100%); */
    transition: all 0.4s ease;
    
}
.propertys .card:hover img{
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
/* hover */


@media screen and (max-width:1024px) {
    .propertys .card{
     
        transform: skewX(0deg);
      
    } 
    .propertys .card h2,
.propertys .card p{
    transform: skewX(0deg);
   
}
.propertys .card img{
    transform: skewX(0deg);
    /* -webkit-filter: grayscale(100%);
    filter: grayscale(100%); */
    transition: all 0.4s ease;
    
}
}
