.kurumsal-card {
  background-color: var(--blueColor);
  color: #f0ffff;
  padding: 10px 10px 10px 15px;
  margin: 10px 0 10px 0;
  transform: skewX(-20deg);
  border-radius: 4px;
}
.kurumsal-icerik.g2 {
  column-gap: 8rem;
}
.kurumsal-card span {
  transform: skewX(20deg);
}

.video-card {
  border-radius: 8px;

  overflow: hidden;
  position: relative;
  inset: 0;
  margin: auto;
  scale: 1;
  transition: 0.8s ease-out;
}
.video-card img {
  scale: 1;
  transition: 0.8s ease;
}
.video-card:hover img {
  scale: 1.1;
  transition: 0.8s ease;
  animation-fill-mode: both;
}
.video-card .shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 36%,
    rgba(15, 117, 188, 0.8) 98%,
    rgba(15, 117, 188, 0.8) 100%
  );
  z-index: 1;
}
@media (max-width: 767px) {
  .video-card {
    width: 75vw;
    height: 45vw;
  }
}
.video-card img {
  width: 100%;
  border-radius: 12px;
}
.video-card .tanitim {
  position: absolute;
  margin: 0;
  left: -7px;
  font-family: sans-serif;
  color: #fff;
  padding: 10px 30px;
  bottom: 75px;
  background: rgba(38, 255, 0, 0.5);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 2;
}
.video-card .tanitim i {
  padding-left: 5px;
  color: #ffffffbb;
}
.video-card p {
  position: absolute;
  margin: 0;
  left: -7px;
  font-family: sans-serif;
  color: #fff;
  padding: 10px 30px;
  bottom: 25px;
  background: rgba(38, 255, 0, 0.5);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transform: skewX(-15deg);
  z-index: 2;
}

@media (max-width: 480px) {
  .video-card p {
    font-size: 14px;
    margin: 0 auto;
    padding: 6px 15px;
    bottom: -10px;
    right: 0;
    left: 0;
    background: #0f46de;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
}
.video-card .video-play-button {
  position: absolute;
  z-index: 10;
  inset: 0;
  margin: auto;
  box-sizing: content-box;
  display: block;
  cursor: pointer;
  width: 32px;
  height: 44px;
  border: none;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}
.video-card .video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  inset: 0;
  margin: auto;
  display: block;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}
.video-card .video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  inset: 0;
  margin: auto;
  display: block;
  width: 80px;
  height: 80px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 50%;
  transition: all 200ms;
}
.video-card .video-play-button:hover:after {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1.5px);
  -webkit-backdrop-filter: blur(1.5px);
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 30px solid #ffffff;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  border-radius: 4px;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 0;
  }
}
.video-overlay {
  position: fixed;
  z-index: -1;
  inset: 0;
  background: rgba(0, 0, 0, 0.55);
  opacity: 0;
  transition: all ease 500ms;
}
.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 200ms;
}
.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  inset: 0;
  margin: auto;
  width: 80vw;
  height: 47vw;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  opacity: 0;
  z-index: 1;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-delay: 0.4s;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 30px, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/* vizyon misyon */

.vision-mision {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.vm-info {
  position: relative;
  color: #ffff;
  overflow: hidden;
  padding: 2rem 0 4rem 0;
  width: 100%;
  background-color: #dfdfdf;
  background: var(--blueColor);
  mask-size: cover;
  mask-repeat: no-repeat;
  border-radius: 3rem;
  scale: 0.8;
  transition: all 0.9 ease-in;
}

.wm-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item {
  position: relative;
  opacity: 0.5;
  padding-bottom: 60px;
  padding-top: 60px;
  padding-left: 60px;
  padding-right: 90px;
  max-width: 1150px;
  transition: all 0.4s ease;
}
.item:hover {
  opacity: 1;
}
.item .m-text:before {
  content: " ";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  border: 1px solid;
  border-color: hsla(0, 0%, 100%, 0.75);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000),
    to(transparent)
  );
  -webkit-mask-image: -webkit-linear-gradient(left, #000, transparent);
  mask-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000),
    to(transparent)
  );
  mask-image: linear-gradient(90deg, #000, transparent);
  background: linear-gradient(
    to bottom,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0.18)
  );
  transform: skewX(-20deg);
  border-radius: 7px;
}
.item .v-text:before {
  content: " ";
  height: 100%;
  right: 0;
  position: absolute;
  top: 0;
  border: 1px solid;
  border-color: hsla(0, 0%, 100%, 0.75);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  transition: all 0.35s ease;
  width: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#000),
    to(transparent)
  );
  -webkit-mask-image: -webkit-linear-gradient(right, #000, transparent);
  mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#000),
    to(transparent)
  );
  mask-image: linear-gradient(270deg, #000, transparent);
  background: linear-gradient(
    to right,
    /* Yönü belirleyin */ hsla(123, 87%, 61%, 0.242),
    /* Başlangıç rengi */ hsla(140, 93%, 65%, 0.256) /* Bitiş rengi */
  );
  transform: skewX(-20deg);
  border-radius: 7px;
}

.v-text h3 {
  text-align: right;
}
.v-text p {
  text-align: right;
}
.m-text p {
  text-align: left;
}

.item .txt {
  color: #ffff;
}

/* sertifika */
.certificate img {
  filter: grayscale(100%);
  transition: all 0.4s ease;
}
.certificate div img:hover {
  filter: grayscale(0%);
}
.vm-info .container {
  overflow: visible;
}
@media screen and (max-width: 1024px) {
  .item .m-text:before {
    transform: skewX(0deg);
  }
  .item .v-text:before {
    transform: skewX(0deg);
  }
  .certificate .g3 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 768px) {
  .kurumsal-icerik.g2 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .kurumsal-icerik h2 {
    margin-top: 4rem;
  }
  .certificate .g3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media screen and (max-width: 430px) {
  .item {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .certificate .g3 img {
    width: 100%;
  }
}
