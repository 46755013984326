.ram-filtre .lg-react-element {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  
  
}
.ram-filtre .lg-react-element img{
  width: 250px;
}
.ram-filtre  .lg-react-element img:hover  {
  scale:1.1;
  
  
}
:hover:not(:hover) {
  filter: blur(5px);
}
.lg-react-element:has(img:hover)
img:not(:hover){
    filter: blur(1.1px);
   
  }
.lg-react-element img{
  border-radius: 6px;
  object-fit: cover;
 object-position: center;
  overflow: hidden;
  height: 180px;
  scale: 1;
  filter: blur(0px);
  transition: all .5s ease;
}
.rambaca-txt{
  background-color: var(--blueColor);
  padding: 40px;
  border-radius: 7px;
  margin-bottom: -5rem;
}
.rambaca-txt p{
  color: #ffffffec;
}
.ram-filtre .container{
  overflow: visible;
}
.banner-img{
  height: 550px;
  object-fit: cover;
  border-radius: 15px

}

  .red{
	background: url('../Assets/filtreparçalari/atik-hava.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
    
}

.green {
	background: url('../Assets/filtreparçalari/ayristirma-unite.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
}

.blue {
	background: url('../Assets/filtreparçalari/elek.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
}

.pink{
	background: url('../Assets/filtreparçalari/ekonomizer.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
}

.white{
	background: url('../Assets/filtreparçalari/elek.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
   
}

.gray{
	background: url('../Assets/filtreparçalari/radyal-fan.png') center center;
     background-size:center;
        object-fit: cover;
    background-repeat: no-repeat;
}
.purple{
	background: url('../Assets/filtreparçalari/yoguşturma.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
}
.damla{
	background: url('../Assets/filtreparçalari/damla-tutucu.png') center center;
    background-size:center;
    object-fit: cover;
    background-repeat: no-repeat;
}

.spacer{
    display: flex;
    gap: 5rem;
}



  .baca-info {
    position: relative;
      width:60%;
      z-index: 2;
      
      
  }
  .baca-info h2{
    font-family: 'montserrat',sans-serif;
  }
  
  .info-images {
      height:100vh;
      /* outline:1px solid purple; */
      width:40%;
      height: auto;
    
  }
  
  .desktopContent {
      margin:auto;
      width:80%;
  }
  
  .info-text {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  .info-images{
    justify-items: center;
    height: 100vh;
    width: 100%;
    top: 0;
   
  }
  .info-image-bl{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
  }
  .info-image {
    width:40vw;
	height:40vw;
	border-radius:20px;
	position:relative;
	overflow:hidden;


  }

  
  .desktopPhoto {
	position:absolute;
	width:100%;
	height:100%;
}


/* emisyon section */
.emisyon-deger{
  background: var(--greenColor);
  padding: 20px;
  border-radius: 7px;
  color: #fff;
  text-align: center;
  transform: skewX(-15deg);

}
.emisyon-deger .txt{
  color: #ffffffd9;
  transform: skewX(15deg);
}
.emisyon-deger h2{
  transform: skewX(15deg);
}
.mobilRambaca{
  display: none;
}
/* emisyon section */
@media screen and (max-width:820px) {
  .spacer{display: none;}
  .mobilRambaca{display: block;}
  
  .rambaca-txt{
    margin-bottom: 4rem;
  }
  .mobilRambaca .info-text{
    margin-top: 4rem;
    align-items: center;
    text-align: center;
    border: 1px solid;
    border-color: rgba(2, 88, 180, 0.413);
    padding: 5px 20px 5px 20px;
    border-radius: 6px;
    
  
  }
  .mobilRambaca h2{
    font-size: clamp(1.5rem, 0.86rem + 3.2vw, 2.5rem);
    font-weight: 800;
    font-family: 'ageo-extrabold',sans-serif;
  
  }
  .mobilRambaca p{
    max-width: 40ch;
  
  }
  .info-text {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  .info-images{
    justify-items: center;
    height: 100vh;
    width: 100%;
    top: 0;
   
  }
  .info-image-bl{
    display: block;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    top: 0;
  }
  .info-image {
    width:40vw;
	height:40vw;
	border-radius:20px;
	position:relative;
	overflow:hidden;


  }
  .lg-react-element img{
    max-width: 200px;
    width: 100%;
  }
}

@media screen and (max-width:768px) {
  .emisyon-deger{
  
    transform: skewX(0deg);
  
  }
  .emisyon-deger .txt{
    color: #ffffffd9;
    transform: skewX(0deg);
  }
  .emisyon-deger h2{
    transform: skewX(0deg);
  }
}
@media screen and (max-width:600px) {
  .ram-filtre  .lg-react-element img{
    max-width: 150px;
    width: 100%;
  }
}