.preloader{
    height: 100vh;
    width: 100%;
    background: #fff;
    color: var(--blueColor);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    width: 550px;
    font-size: 30px;
    font-weight: 800;
    overflow: hidden;
    color:var(--blueColor);
}
@media screen and (max-width:620px) {
    .preloader .pre-text{
        display: none;
    }
    .preloader .texts-container{
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
}