
.CertificateP .g4{
    gap: 30px;
}
.CertificateP .wrapper a p{
    margin-top: 2rem;
}
.CertificateP .lg-react-element img {
    font-family:'Courier New', Courier, monospace ;
}
.CertificateP .flex{
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 5rem;
    align-items: center;
    text-align: center;
    max-width: 100%;
}
.CertificateP img{
    max-width: 100%;
    width: 300px;
    height: auto;
    transition: all .4s ease;
    filter: grayscale(100%);
}
.CertificateP img:hover{
    filter: grayscale(0);
}
