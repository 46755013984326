.hizmetler-p .container{
    overflow: visible;
}

.hizmet-card{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--blueColor);
    padding: 30px;
    transform: skewX(-15deg);
    backdrop-filter: blur( 4.5px );
    -webkit-backdrop-filter: blur( 4.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    width: 100%;
    height: 300px;
    color: #fff;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    top: 0;
    transition: all .4s ease;
}
.hizmet-card h3{
    background-color: #01ad4e6e;
    padding: 8px;
    border-radius: 5px;
    transform: skewX(15deg);
}
.hizmet-card h3{
    display: flex;
    text-align: center;
    justify-content: center;
}
.hizmet-card .svc-img{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0px;
    left: 0;
    opacity: 1;
    transition: all .4s ease;
   
}
.hizmet-card .svc-img img{
    object-position: center;
    opacity: .2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .6s ease;
}
.hizmet-card:hover{top: -10px;}
.hizmet-card:hover img{
    scale: 1.1;
}
@media screen and (max-width:800px) {
    .hizmet-card{
        transform: skewX(0);
    }
    .hizmet-card h3{
        transform: skewX(0deg);
    }
}
@media screen and (max-width:606px) {
    .hizmet-cards.g3{
        grid-template-columns: 1fr 1fr;
        gap: 12px;
    }
}
@media screen and (max-width:425px) {
    .hizmet-cards.g3{
        grid-template-columns: 1fr ;
        gap: 20px;
    }
    .hizmet-card:hover{scale:.98; top: 0;}
}