.Info {
  position: relative;
}
.Info .logo-banner {
  width: 100%;
  border-radius: 20px;
  -webkit-mask-image: url("../Assets/2n-favicon.png");
  mask-image: url("../Assets/2n-favicon.png");

  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
/* .logo-banner{
    position: absolute;
    width: 320px;
    height: auto;
    opacity: .7;
    filter: grayscale(100%);
    left:-154px
} */

.Info .sag {
  height: 100%;
  position: relative;
}
.logo-banner {
  z-index: 9999;
}
.logo-line {
  position: absolute;
  width: 96%;
  filter: opacity(10%);
  top: 25px;
  right: 35px;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .Info .g2c {
    grid-template-columns: 1fr;
    justify-content: center;
    grid-row-gap: 1%;
  }
}
