.news-card{
    position: relative;
   /* border: 1px solid #b0b0b0; */
 
   padding: 13px 13px 40px 13px;
   height: 400px;
   overflow: hidden;
   background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(0, 140, 255, 0) 6%, rgba(255, 255, 255, 0.485) 63%, rgba(255, 255, 255, 0.726) 80%, rgb(255, 255, 255) 92%);
}
.news-card .card-img{
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    border-radius: 15px;
    top: 0;
    left: 0;
    isolation: isolate;
    z-index: -1;
   
}
.news-card img{
    
    object-fit: cover;
    border-radius: 15px;
    object-position: center;
    display: block;
    width: 100%;
    height: 100%;
    transition: all 0.8s ease;
   
}
.news-card:hover img{
    scale: 1.2;
}
.card-icerik{
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  
}
.card-icerik p{
    font-family: 'montserrat',sans-serif;
    font-weight: 700;
    letter-spacing: .5px;
}

.kategori{
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    border: 1px solid var(--blueColor);
    padding: 2px 15px 2px 15px ;
    border-radius: 13px;
    font-size: 18px;
    color: var(--blueColor);
}









.slick-slide > div{
    padding: 30px;
}







 .slick-center{
    display: block;
}
  /* Default slide */

 .slick-slide,
  .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
    transform: scale(1, 1);
    transition: all 0.4s ease-in-out;
  }
  
  /* Active center slide (You can change anything here for cenetr slide)*/
.slick-center,
 .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
    transform: scale(1);
 
  }
  .slick-current{
    display:none;
  }
 .slick-current.slick-active{
    display:block
    
  }
  /* .slick-arrow{
    top: 0 !important;
  } */
  /* .slick-next, .slick-prev{
    z-index: 5;
    background-color: #000 !important;
    color: #b0b0b0;
    
  } */
  .slick-next{
    right: 15px;
    background-color: #000;
  }
  .slick-prev{
    left: 15px;
  }
  .slick-next:before, .slick-prev:before{
    color: #000;
    font-size: 26px;
  }