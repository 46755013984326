.service .container {
  overflow: visible;
}

.service {
  background-color: #ffffff;
  display: flex;
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.altservice {
  position: relative;
  color: #ffff;
  overflow: hidden;
  padding: 2rem 0 4rem 0;
  width: 100%;
  background-color: #dfdfdf;
  background: linear-gradient(rgb(90, 186, 255), rgba(15, 116, 188, 0.54));
  mask-size: cover;
  mask-repeat: no-repeat;
  border-radius: 3rem;
  scale: 0.8;
  transition: all 0.9 ease-in;
}
.clouds {
  position: absolute;
  filter: blur(6px);
  opacity: 0.8;
  display: flex;
  pointer-events: none;
}
.service h2 {
  text-align: center;
}

/* swiper cards */

.mySwiper {
  margin-top: 7rem;
  margin-bottom: 7rem;
  height: 500px;
  width: 430px;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}
.mySwiper h3 {
  width: 10ch;
}
.mySwiper .swiper-slide {
  position: relative;

  align-items: center;
  justify-content: center;
  border-radius: 13px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  padding-right: 20px;
  backdrop-filter: blur(20px);
  background: linear-gradient(
    rgba(15, 116, 188, 0.88),
    rgba(255, 255, 255, 0.5)
  );
  padding: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff9a;
  overflow: hidden;
  margin-right: 10px;
  transition: all 0.3s ease;
}
.mySwiper ul li {
  -webkit-transition-timing-function: cubic-bezier(0.77, 0.16, 0.09, 0.94);
  -moz-transition-timing-function: cubic-bezier(0.77, 0.16, 0.09, 0.94);
  transition-timing-function: cubic-bezier(0.77, 0.16, 0.09, 0.94);
}

.swiper-wrapper {
  justify-content: center;
  cursor: pointer;
}
.service .swiper-pagination {
  display: none;
}
.service .swiper .svc-img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  transition: all 0.6s ease;
}
.service .swiper .svc-img img {
  object-position: center;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.6s ease-in;
}
/* svc contents */

.svc-txt a {
  font-size: clamp(1.8rem, 1.6158rem + 0.7368vw, 2.5rem);
  color: #eaeaea;
  letter-spacing: 0.4px;
  line-height: 33px;
  font-weight: 500;
  font-family: "ageo-light", sans-serif;
  transition: all 0.45s ease;
  padding-bottom: 10px;
}
.svc-content {
  margin-top: 0px;
  visibility: hidden;
  opacity: 0;
  transition: 0.8s ease;
}

.svc-icon {
  transform: rotate(-50deg);
  transition: all 0.45s ease;
  font-size: 16px;
}
.svc-txt:hover .svc-icon {
  transform: rotate(0deg);
}
.svc-txt:hover a {
  font-weight: 700;
}
.iconimg {
  transition: 0.6s ease;
  visibility: visible;
}
.iconimg2 {
  position: absolute;
  transition: 0.6s ease;
  opacity: 0;
  visibility: hidden;
  width: 11%;
  right: 30px;
  top: 0px;
}
/* svc contents */

.mySwiper .swiper-slide:hover .svc-img {
  opacity: 0;
  transition: all 0.9s ease;
}
.mySwiper .swiper-slide:hover .svc-content {
  margin-top: 50px;
  opacity: 1;
  transition: all 0.1 ease;
  visibility: visible;
}
.mySwiper .swiper-slide:hover .iconimg {
  margin-bottom: -50px;
  opacity: 0;
  visibility: hidden;
}
.mySwiper .swiper-slide:hover .iconimg2 {
  top: 40px;
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 614px) {
  .mySwiper {
    height: 500px;
    width: 90%;
  }
  .mySwiper h3 {
    width: 10ch;
  }
  .mySwiper .swiper-slide {
    position: relative;

    align-items: center;
    justify-content: center;
    border-radius: 13px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding-right: 20px;
    backdrop-filter: blur(20px);
    background: linear-gradient(
      rgba(15, 116, 188, 0.88),
      rgba(255, 255, 255, 0.5)
    );
    padding: 40px;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff9a;
    overflow: hidden;
    margin-right: 10px;
    transition: all 0.3s ease;
  }
}

@media screen and (max-width: 680px) {
  .swiper-pagination {
    display: block;
  }
}

/* icon */

.iconimg {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

/* ram baca sayfası */
.baca-resmi {
  position: relative;
}
.baca-resmi img {
  width: 850px;
}

.rambaca .bslk-txt {
  font-size: clamp(1.5rem, 1.3158rem + 0.7368vw, 2.2rem);
}
.rambaca .g2 ul li {
  padding-bottom: 18px;
  list-style: inside;
}
.cimen {
  position: absolute;
  bottom: -10%;
  z-index: -1;
  filter: blur(1px) saturate(130%);
  opacity: 1;
}

/* artilar */
.artilar {
  cursor: pointer;
  transition: all 1s ease;
}
.artilar i {
  transition: all 1s ease;
}

.artilar.no1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 200px;
  left: 50px;
}
.artilar.no2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 220px;
  left: 180px;
}
.artilar.no3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 350px;
  left: 310px;
}
.artilar.no4 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 390px;
  left: 580px;
}
.artilar.no5 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 245px;
  right: 30px;
}
.artilar.no6 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 150px;
  right: 130px;
}
.artilar.no7 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 135px;
  right: 210px;
}
.artilar.no8 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--greenColor);
  font-size: 20px;
  position: absolute;
  top: 130px;
  right: 290px;
}
.artilar.no9 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--greenColor);
  font-size: 20px;
  position: absolute;
  top: 110px;
  left: 340px;
}
.artilar.no10 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--blueColor);
  font-size: 20px;
  position: absolute;
  top: 79px;
  left: 125px;
}

/* arti hover durumu */
.artilar .aciklama {
  padding: 8px;
  width: 200px;
  /* height: 165px; */
  background-color: var(--blueColor);
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: 50px;
  left: -75px;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}
.artilar .aciklama p {
  font-family: "montserrat", sans-serif;
}
.artilar:hover .aciklama {
  opacity: 1;
  visibility: visible;
}
.artilar:hover i {
  transform: rotate(45deg);
}

@media screen and (max-width: 1279px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .artilar.no3 {
    top: 350px;
    left: 340px;
  }

  .artilar.no1 {
    top: 200px;
    left: 70px;
  }

  .artilar.no8 {
    top: 140px;
    right: 290px;
  }
}
@media screen and (max-width: 1025px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 998px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .baca-resmi img {
    width: 700px;
  }
  .artilar.no1 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 175px;
    left: 45px;
  }
  .artilar.no2 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 200px;
    left: 180px;
  }
  .artilar.no3 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 290px;
    left: 250px;
  }
  .artilar.no4 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 320px;
    left: 380px;
  }
  .artilar.no5 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 200px;
    right: 25px;
  }
  .artilar.no6 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 125px;
    right: 105px;
  }
  .artilar.no7 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 125px;
    right: 160px;
  }
  .artilar.no8 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 120px;
    right: 240px;
  }
  .artilar.no9 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 90px;
    left: 260px;
  }
  .artilar.no10 {
    height: 35px;
    width: 35px;
    font-size: 17px;
    top: 79px;
    left: 125px;
  }
}
@media screen and (max-width: 767px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .baca-resmi img {
    width: 500px;
  }
  .artilar.no1 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 125px;
    left: 40px;
  }
  .artilar.no2 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 140px;
    left: 120px;
  }
  .artilar.no3 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 210px;
    left: 190px;
  }
  .artilar.no4 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 230px;
    left: 280px;
  }
  .artilar.no5 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 200px;
    right: 25px;
  }
  .artilar.no6 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 95px;
    right: 130px;
  }
  .artilar.no7 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 100px;
    right: 80px;
  }
  .artilar.no8 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 70px;
    right: 285px;
  }
  .artilar.no9 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 90px;
    left: 300px;
  }
  .artilar.no10 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 79px;
    left: 125px;
  }
}
@media screen and (max-width: 640px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .baca-resmi img {
    width: 500px;
  }
  .artilar.no1 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 120px;
    left: 75px;
  }
  .artilar.no2 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 135px;
    left: 140px;
  }
  .artilar.no3 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 200px;
    left: 220px;
  }
  .artilar.no4 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 230px;
    left: 330px;
  }
  .artilar.no5 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 150px;
    right: 50px;
  }
  .artilar.no6 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 95px;
    right: 160px;
  }
  .artilar.no7 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 100px;
    right: 115px;
  }
  .artilar.no8 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 70px;
    right: 320px;
  }
  .artilar.no9 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 90px;
    left: 330px;
  }
  .artilar.no10 {
    height: 28px;
    width: 28px;
    font-size: 14px;
    top: 50px;
    left: 140px;
  }
}
@media screen and (max-width: 577px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .baca-resmi img {
    width: 370px;
  }
  .artilar.no1 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 90px;
    left: 35px;
  }
  .artilar.no2 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 100px;
    left: 80px;
  }
  .artilar.no3 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 150px;
    left: 140px;
  }
  .artilar.no4 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 170px;
    left: 200px;
  }
  .artilar.no5 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 90px;
    left: 330px;
  }
  .artilar.no6 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 75px;
    left: 290px;
  }
  .artilar.no7 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 70px;
    right: 85px;
  }
  .artilar.no8 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 50px;
    right: 210px;
  }
  .artilar.no9 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 60px;
    left: 215px;
  }
  .artilar.no10 {
    height: 23px;
    width: 23px;
    font-size: 11px;
    top: 45px;
    left: 75px;
  }
  .artilar.no5 .aciklama {
    bottom: 50px;
    left: -135px;
    font-size: 12px;
  }
  .artilar.no6 .aciklama {
    bottom: 50px;
    left: -135px;
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  .rambaca .g2 {
    grid-template-columns: 1fr;
  }
  .rambaca .g2 div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .baca-resmi img {
    width: 300px;
  }
  .artilar.no1 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 90px;
    left: 35px;
  }
  .artilar.no2 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 100px;
    left: 80px;
  }
  .artilar.no3 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 150px;
    left: 140px;
  }
  .artilar.no4 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 170px;
    left: 200px;
  }
  .artilar.no5 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 90px;
    left: 330px;
  }
  .artilar.no6 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 75px;
    left: 290px;
  }
  .artilar.no7 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 70px;
    right: 85px;
  }
  .artilar.no8 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 50px;
    right: 210px;
  }
  .artilar.no9 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 60px;
    left: 215px;
  }
  .artilar.no10 {
    height: 22px;
    width: 22px;
    font-size: 10px;
    top: 45px;
    left: 75px;
  }
  .artilar.no5 .aciklama {
    bottom: 50px;
    left: -135px;
    font-size: 12px;
  }
  .artilar.no6 .aciklama {
    bottom: 50px;
    left: -135px;
    font-size: 12px;
  }
}

/* kuş animasyonu  */

.bird {
  background-image: url("../Assets/bird.svg");
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
  animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
  animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird--one {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.bird--two {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}
.bird--three {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.bird--four {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  bottom: 40%;
  left: -10%;
  z-index: -1;
  transform: scale(0) translateX(-10vw);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
  animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.bird-container--one {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.bird-container--two {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.bird-container--three {
  -webkit-animation-duration: 14.6s;
  animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}
.bird-container--four {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}
@-webkit-keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
@keyframes fly-right-one {
  0% {
    transform: scale(0.3) translateX(-10vw);
  }
  10% {
    transform: translateY(2vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(0vh) translateX(30vw) scale(0.5);
  }
  30% {
    transform: translateY(4vh) translateX(50vw) scale(0.6);
  }
  40% {
    transform: translateY(2vh) translateX(70vw) scale(0.6);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.6);
  }
  60% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.6);
  }
}
@-webkit-keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }
  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }
  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }
  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }
  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
@keyframes fly-right-two {
  0% {
    transform: translateY(-2vh) translateX(-10vw) scale(0.5);
  }
  10% {
    transform: translateY(0vh) translateX(10vw) scale(0.4);
  }
  20% {
    transform: translateY(-4vh) translateX(30vw) scale(0.6);
  }
  30% {
    transform: translateY(1vh) translateX(50vw) scale(0.45);
  }
  40% {
    transform: translateY(-2.5vh) translateX(70vw) scale(0.5);
  }
  50% {
    transform: translateY(0vh) translateX(90vw) scale(0.45);
  }
  51% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
  100% {
    transform: translateY(0vh) translateX(110vw) scale(0.45);
  }
}
