
   .contact .container {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
   }
  
    .contact .maps{
    position: relative;
    margin-bottom: -4rem;
    webkit-mask-image: -webkit-radial-gradient(50% 50%, ellipse, #000 25%, transparent 80%);
    mask-image: radial-gradient(ellipse at 50% 50%, #000 25%, transparent 90%);
    background-color: rgba(0, 0, 0, .5);
      width: 100%;
      height: 100%;
      z-index: 0;
      
      
    }
    
    .maps .harit{
        position: relative;
        width: 100%;
        height: 100%;
        
    }
    .map-marker{
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 40%;
        right: 35%;
        transform: translateX(10%);
        z-index: 999;
        background-color: #abe4fc;
        padding: 20px;
        padding-left: 25px;
        padding-right: 25px;
        transform: skewX(-20deg);
        transition: all .4s ease; border-radius: 6px;
    }
    .map-marker:hover{
        top: 38%;
    }
    .map-marker:hover img{
        scale: 1.08;
    }
    .map-marker div{
        pointer-events: all;
        cursor: pointer;
        display: flex;
        gap: 50px;
        align-items: center;
        transform: skewX(20deg);

    }
    .map-marker img{
        transition: all .4s ease;

    }
    .contact .harita{
      position: relative;
    }
   
    .contact-text{
        padding-top: 90px;
        padding-bottom: 90px;
        padding-left: 60px;
        font-family: 'montserrat',sans-serif;
        

    }
    .contact-text .contact-icerik{
        width: 30ch;
    }
    .contact-text p,.contact-text h2,.contact-text li{
        font-family: 'montserrat',sans-serif;
    }
    .contact-text h2{
        color: var(--blueColor);
    }
    .contact .bslk-txt{
        font-size: 22px;
        font-weight: 600;
      }
    .contact-text ul li{
        padding-bottom: 15px;
        transition: all .8s ease;
    }
    .contact-text ul li:hover{
        color: var(--blueColor);        
    }
    .contact .g2{
        grid-template-columns: minmax(0, 40fr) minmax(0, 23fr);
    }
    .contact-text {
        display: none; /* Başlangıçta gizle */
    }
    
    .contact-text.active {
        display: block; /* Aktif olduğunda göster */
    }
    /* contact button */

    .contact-button{
      
        position: absolute;
        transform: translateY(-50%);
        top: -10%;
        right: 100%;
        height: 100%;
        writing-mode: vertical-rl;
        transform: rotateY(180deg);
        transform: rotateZ(180deg);
        display: flex;
    }
    .contact-button div:first-child{
        margin-bottom: 100px;
        padding-left: 22px;
       
    }
    .contact-button .txt3 {
        position: relative; 
        cursor: pointer;
    }
    .contact-button .txt3:after{
        content: '';
        position: absolute;
        display: flex;
        height: 100%;
        width: 2px;
        top: 0;
        left: 0;
        background-color: var(--blueColor);
        transform: scale(0);
        transition: all .4s ease;
    }
    .contact-button .txt3.active:after {
        transform: scale(1);
    }
    .contact-button .txt3.active{
        color: var(--blueColor);
    }


    /* form */
    .formbold-mb-5{
      margin-bottom: 14px;
    }
    .entryarea{
        position: relative;
        height: 80px;
        line-height: 70px;
        font-family: 'montserrat',sans-serif;
    }
    input,textarea{
        position: absolute;
        width: 100%;
        outline: none;
        font-size: 1em;
        padding: 0 20px;
        border-radius: 7px;
        border: 1px solid #9e9e9e;
        background: transparent;
        z-index:1111 ;
        
    }
    textarea{
      padding: 20px 20px;
    }
    .labelline{
      
        position: absolute;
        font-size: 1.2em;
        color: #999999;
        padding: 0 14px;
        margin: 0 10px;
        background-color: #ffffff;
        transition: 0.2s ease;
    }
    input:focus,
    input:valid{
        color: var(--blueColor);
        border: 1px solid var(--blueColor);
    }
    input:focus + .labelline,
    input:valid + .labelline,
    textarea:focus + .labelline,
    textarea:valid + .labelline{
        color: var(--blueColor);
        height: 30px;
        line-height: 30px;
        transform: translate(-15px,-16px) scale(0.88);
        z-index: 1111;
    }
    

    /* form */
    /* .maps {
      grid-area: item1;
    }
    
    .item2 {
      grid-area: item2;
    } */



    @media screen and (max-width: 1280px) {
        .contact .g2{
            grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
        }
    }
    @media screen and (max-width: 1024px) {
        .contact .g2{
            grid-template-columns: repeat(1, minmax(0, 1fr));
          
           
        
        }
        .maps .harit{
          position: relative;
          width: 100%;
          height: 500px;
          
      }
      .contact-button{
        position: absolute;
        display: flex;
        right: inherit;
        background-color: rgba(0, 0, 0, .03);
        width: 40px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 6px;
        top: 28%;
        
       
      }
      .contact-button .txt3 {
        position: relative; 
        cursor: pointer;
        font-size: 19px;
    }
    .contact-text .contact-icerik{
     padding: 15px;
      width: 100%;
    }
    .map-marker{
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%);
    }
    .map-marker div{
      transform: skewX(0deg);
  }
    }
    @media screen and (max-width: 768px) {
      .map-marker img{
        display: none;
      }
      .map-marker .sep-horizontal{
        display: none;
      }
      .map-marker:hover{
        top: 46%;
      }
    }
  
  @keyframes rotateIcon {
      0% {
          transform: scaleX(0deg);
      }
      100% {
          transform: scaleX(10deg);
      
      }
  }
    @keyframes shadow {
      from {filter: drop-shadow(0px 0px 0px #ffffff);}
      to {filter: drop-shadow(0px 0px 150px #ffffff);}
      
     
    }
 
  
    .notification {
      position: fixed;
      top: -100px; /* Bildirimi başlangıçta ekranın dışında tutuyoruz */
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      border-radius: 5px;
      z-index: 1000;
      background-color: #fff; /* Bildirim arka plan rengi */
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Hafif bir gölgelendirme ekliyoruz */
      transition: top 0.3s ease-in-out; /* Bildirimin yukarı doğru hareketi için geçiş efekti */
  }
  
  .notification.success {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); /* Bildirimi yukarıya hareket ettiriyoruz */
      background-color: #d4edda; /* Başarılı bildirim arka plan rengi */
  }
  
  .notification.error {
      top: 20px; /* Bildirimi yukarıya hareket ettiriyoruz */
      background-color: #f8d7da; /* Hata bildirimi arka plan rengi */
  }
  
  /* loading animate */
  .dot-spinner {
      --uib-size: 2.8rem;
      --uib-speed: .9s;
      --uib-color: #ffffff;
      position: relative;
      
      
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: var(--uib-size);
      width: var(--uib-size);
    }
    
    .dot-spinner__dot {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
    }
    
    .dot-spinner__dot::before {
      content: '';
      height: 20%;
      width: 20%;
      border-radius: 50%;
      background-color: var(--uib-color);
      transform: scale(0);
      opacity: 0.5;
      animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
      box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
    }
    
    .dot-spinner__dot:nth-child(2) {
      transform: rotate(45deg);
    }
    
    .dot-spinner__dot:nth-child(2)::before {
      animation-delay: calc(var(--uib-speed) * -0.875);
    }
    
    .dot-spinner__dot:nth-child(3) {
      transform: rotate(90deg);
    }
    
    .dot-spinner__dot:nth-child(3)::before {
      animation-delay: calc(var(--uib-speed) * -0.75);
    }
    
    .dot-spinner__dot:nth-child(4) {
      transform: rotate(135deg);
    }
    
    .dot-spinner__dot:nth-child(4)::before {
      animation-delay: calc(var(--uib-speed) * -0.625);
    }
    
    .dot-spinner__dot:nth-child(5) {
      transform: rotate(180deg);
    }
    
    .dot-spinner__dot:nth-child(5)::before {
      animation-delay: calc(var(--uib-speed) * -0.5);
    }
    
    .dot-spinner__dot:nth-child(6) {
      transform: rotate(225deg);
    }
    
    .dot-spinner__dot:nth-child(6)::before {
      animation-delay: calc(var(--uib-speed) * -0.375);
    }
    
    .dot-spinner__dot:nth-child(7) {
      transform: rotate(270deg);
    }
    
    .dot-spinner__dot:nth-child(7)::before {
      animation-delay: calc(var(--uib-speed) * -0.25);
    }
    
    .dot-spinner__dot:nth-child(8) {
      transform: rotate(315deg);
    }
    
    .dot-spinner__dot:nth-child(8)::before {
      animation-delay: calc(var(--uib-speed) * -0.125);
    }
    
    @keyframes pulse0112 {
      0%,
      100% {
        transform: scale(0);
        opacity: 0.5;
      }
    
      50% {
        transform: scale(1);
        opacity: 1;
      }
    }