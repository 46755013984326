.Reference h2 {
  font-size: 3rem;
  text-align: center;
}
.Reference .cards div {
  color: #b0b0b0;
  width: 100%;
  height: 210px;
  border: 1px solid #b0b0b0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;
  margin: 0 10px 0 10px;
  border-radius: 15px;
  transition: all 1s ease;
  margin-top: 30px;
}
.Reference .container .bb {
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%, 50%);
}

.Reference .cards div:hover {
  border-color: #5a5a5a;
}
.Reference .cards img {
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  transition: all 0.4s ease;
  max-width: 200px;
}
.Reference .cards div:hover img {
  /* -webkit-filter: grayscale(0%);
    filter: grayscale(0%); */
  scale: 1.1;
}
@media screen and (max-width: 1212px) {
  .Reference .cards.g4 {
    grid-row-gap: 0;
  }
}

@media screen and (max-width: 1024px) {
  .Reference .cards {
    margin-top: 10%;
  }
}
@media screen and (max-width: 768px) {
  .Reference .cards div {
    width: auto;
  }
}
