footer{
    z-index: -2;
}
.foot{
    overflow: hidden;
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 0;
    border-top: 1px solid #70707074;
}
footer .footer-banner{
    position: absolute;
    width: 500px;
    bottom:-360px;
    left: -150px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
    transform:rotate(-50deg);
    opacity: .2;
    animation: shadowAnimation 5s infinite;
}
.footer-baca{
    position: absolute;
    width: 100%;
    
    overflow: hidden;
    mask-image: radial-gradient(ellipse 100% 100% at 50% 50%, rgba(0, 0, 0, 1) 0, transparent 45%);
    z-index: 0;
    transform:rotate(-50deg) blur(90%);
    opacity: .3;
    animation: shadowAnimation 5s infinite;
}
@keyframes shadowAnimation {
    0% {
        filter: drop-shadow(20px 10px 30px #4444dd);
    }
    50% {
        filter: drop-shadow(10px 5px 10px #4444dd);
    }
    100% {
        filter: drop-shadow(20px 10px 30px #4444dd);
    }
}
footer p{
    font-family: "montserrat",sans-serif; 
}
.foot-text{
    font-weight: 700;
    width: 35ch;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.sec.aboutus{
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
  
}

.foot .container{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.foot .container .sec h2{
  position: relative;
  font-weight:600;
  margin-bottom: 15px;
}
.foot .container .quicklinks{
  position: relative;
 
}
.foot .container .quicklinks ul li a{
  margin-bottom: 10px;
  display: inline-block;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  font-family: "montserrat",sans-serif;
  
}
.foot .container .quicklinks ul li{
  overflow: hidden;
}
.foot .container .contact .info{
  position: relative;
  
}
.foot .container .contact .info li span{
  display: flex;
  grid-template-columns: 30px 1fr;
  margin-bottom: 16px;
  align-items: center;
  grid-gap: 12px;
  font-family: "montserrat",sans-serif;
}
.foot .container .contact .info li span a{
  font-family: "montserrat",sans-serif;
}
.foot .container .contact .info ion-icon{
  font-size: 24px;
  color: var(--blueColor);
}
.foot .container .quicklinks a{
  position: relative;
  font-family: "montserrat",sans-serif;

}
.copyrightText p{
  font-family: "montserrat",sans-serif;
    
}
/* hover kısmı */
.foot .container .quicklinks a:hover{
  
  transform: translateX(10px);
  
 
}
.foot .container .quicklinks a:hover::before{
  
  display: block;
  transition: 0.3s ease-in-out;
}

.foot .container .quicklinks a::before{
  content: " ";
  width: 10px;
  background-color: #000000;
  position: absolute;
  left: -15px;
  top: 50%;
  height: 2px;
  display: flex;
  align-items: center;
  transition: .2s;
}
.copyrightText{
    width: 100%;
    padding: 0px 100px 20px;
    color: var(--textGray);
   
  }
  .copyrightText .container{
    border-top: 1px solid rgba(0, 0, 0, 0.333);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* socialIcon */

  .middle{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;


  }
  .social-btn{
    display: inline-block;
    width: 50px;
    height: 50px;
 
    margin: 10px;
    border-radius:30% ;
    
    overflow: hidden;
    position: relative;
  }

.social-btn img{
    width: 100%;
}
 

.footerban{
  opacity: 0.1;
  position: absolute;
  transform: translateX(40%);
  z-index: -99;
  left: 0;
  bottom: -100px;
  
  

}

  @media (max-width:991px) {
    .foot{
      padding: 40px;
    }
    .foot .container{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap:20px
    }
    .copyrightText{
      padding: 20px 40px 30px;
    }
  }
  @media (max-width:768px) {
    .foot{
      padding: 40px;
      margin-top: 10rem;
    }
    .foot .container{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1,1fr);
      grid-gap:20px
    }
    .copyrightText{
      padding: 20px 40px 30px;
    }
    .copyrightText .container{
      display: flex;
      flex-direction: column;
    }
    .copyrightText .container p{
      text-align: center;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:698px) {
     .footer-logo img{
      width: 75%;
     }
  }

  @media screen and (max-width:553px) {
    .middle{
      flex-wrap: wrap;
    }
    .foot-text{
     
      width: 100%;
     
  }
  }