.breadCrumps{
    position: relative;
    padding-bottom: 90px;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background:  radial-gradient(closest-side, #3f87a6b7, var(--blueColor)),url('../Assets/footer-baca.jpg');
    overflow: hidden;

    padding-top: 120px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    
    
  }
  
  .breadText{
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    text-wrap: wrap;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 2;
  }
  .breadTexts{
    display: flex;
    word-break: break-all;
  
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    
    transition: all 0.4s ease;
   
    font-size: clamp(13px,1.31vw,20px);
    font-weight: 400;
    letter-spacing: 3.5px;
    color: hsla(0,0%,100%,.5);
    


  }
  .breadTexts a{
    transition: all 0.4s ease;
    
  }
  .breadTexts a:hover{
    color: #fff;
  }
  .breadTexts p{
    color: #fff;
  }
  .bread-bslktxt{
    display: block;
  }
  .bread-bslktxt h3{
    font-weight: 800;
    font-size: 40px;
    text-align: center;
  }
  .breadSplit{
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 15px;
    width: 2px;
    transform: skew(-15deg);
    background-color:hsla(0,0%,100%,.5) ;
    
    margin-left: 15px;
    margin-right: 15px;
   

  }
.breadCrumps .container{
  overflow: visible;
}
@media screen and (max-width:1024px) {
  .bread-baslik{
    display: none;
  }
 
  .breadText{
    display: flex;
  }
  
  .breadCrumps{
    padding-bottom: 45px;
    padding-top: 90px;
  }
}
@media screen and (max-width:768px) {
  .breadText {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
}
@media screen and (max-width:640px) {
  .breadCrumps .container{
    margin-inline: initial;
  }
}

 